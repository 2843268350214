
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { GroupDTO } from '@/data/dto/group.dto';
import { UserDTO } from '@/data/dto/user.dto';
import ModalFormHeader from '@/components/ModalFormHeader.vue';
import SearchBox from '@/components/SearchBox.vue'; 

type SimpleUser = { name: string; email: string };

@Component({
  components: {
    ModalFormHeader,
    SearchBox,
  }
})
export default class ManageGroupUsers extends Vue {
  @Prop() private currentGroup!: GroupDTO;

  showUsersList = false;

  //todo:: melhorar nomenclatura
  allUsers: UserDTO[] = [];
  listedUsers: UserDTO[] = [];
  groupUsers: SimpleUser[] = [];
  usersToAdd: SimpleUser[] = [];

  // remove users already associated to group
  get groupUsersEmail(): Array<string | undefined> {
    return this.groupUsers.map(u => u.email);
  }
  get filteredUsers(): UserDTO[] {
    return this.allUsers
      .filter(u => !(this.groupUsers.map(u => u.email).includes(u.email)));
  }

  async getUsers(): Promise<void> {
    try {
      const { users } = await this.$store.dispatch('getAllUsers');
      this.allUsers = users;
    } catch (e) {
      this.allUsers = [];
    }
  }

  async getGroupUsers(): Promise<void> {    
    try {
      const { users } = await this.$store.dispatch('getUsersFromGroup', this.currentGroup.uuid);
      this.groupUsers = users;
    } catch (e) {
      this.groupUsers = [];
    }
  }

  async removeUserFromGroup(user:SimpleUser, index: number): Promise<void> {
    try {
      await this.$store.dispatch('removeUserFromApplicationGroup', {
        email: user.email,
        groupUUID: this.currentGroup.uuid,
      });      
      this.$store.commit('showAlert', { message: 'Usuário removido com sucesso!', type: 'success'});
      this.groupUsers.splice(index, 1);
    } catch (e) { return; }
  }

  async addUsersToGroup(): Promise<void> {
    try {
      const newUsersEmails = this.usersToAdd.map(user => user.email);
      await this.$store.dispatch('addMultipleUsersToApplicationGroup', {
        uuid: this.currentGroup.uuid,
        users: newUsersEmails
      });      
      this.$store.commit('showAlert', { message: 'Usuários adicionados com sucesso!', type: 'success'});
      this.groupUsers.push(...this.usersToAdd);
      this.usersToAdd.splice(0);
      this.showUsersList = false;
    } catch (e) { return; }
  }
  
  onSearchFinished(result:UserDTO[]): void {
    this.listedUsers = result;
    this.showUsersList = true;
  }

  onSearchReseted(result:UserDTO[]): void {
    this.listedUsers = result;
    this.showUsersList = false;
  }  

  cancel(): void {
    this.$emit('canceled'); 
  }

  @Watch('allUsers')
  onUsersChanged(users: UserDTO[]): void {
    this.listedUsers = users;
  }  

  async mounted(): Promise<void> {
    await this.getGroupUsers();
    await this.getUsers();
  }
}
