
import { Component, Watch, Mixins } from 'vue-property-decorator';
import RouteTitle from '@/components/RouteTitle.vue';
import SearchBox from '@/components/SearchBox.vue';
import { GroupDTO } from '@/data/dto/group.dto';
import CreateGroup from '@/views/Admin/Groups/CreateGroup.vue';
import ManageGroupUsers from '@/views/Admin/Groups/ManageGroupUsers.vue';
import Pagination from '@/mixins/pagination.mixin';
import { Weekday } from '@/data/dto/weekday.dto';

type ModalMode = 'EDIT_GROUP' | 'MANAGE_USERS' | null;
type Permission = { key: string; value: string };
type CreateGroupPayload = { name: string; permissions: Permission[]; uuid: string | undefined; hidden?: boolean };

@Component({
  components: {
    RouteTitle,
    SearchBox,
    CreateGroup,
    ManageGroupUsers,
  },
})
export default class Groups extends Mixins(Pagination) {
  modalIsVisible = false;
  modalMode:ModalMode = null;

  groupToEdit: GroupDTO | null = null;

  allGroups: GroupDTO[] = [];
  filteredGroups: GroupDTO[] = [];

  get groupsInCurrentPage(): GroupDTO[] {
    return this.itensInCurrentPage(this.filteredGroups);
  }

  statusLabel(status: boolean): string {
    return status ? 'Ativo' : 'Inativo';
  }

  private limitedSizeString(text: string, size = 30): string {
    if (!text) return '';
    if (text.length <= size) return text;
    return `${text.substring(0, size)}...`;
  }

  marketsAndTimesLabel(data: string[] = []): string {
    const text = Array.isArray(data) ? data.join(', ') : data;
    return this.limitedSizeString(text);
  }

  checkDay(group: GroupDTO, day: Weekday): boolean {
    return (!!group.policies && !!group.policies.weekDay && (group.policies.weekDay.indexOf(day) > -1));
  }

  onSearchFinished(result:GroupDTO[]): void {
    this.filteredGroups = result;
  }

  onSearchReseted(): void {
    this.filteredGroups = this.allGroups;
  }

  editGroup(group: GroupDTO): void {
    this.setGroupToEdit(group);
    this.setModalMode('EDIT_GROUP');
    this.showModal();
  }

  createGroup(): void {
    this.setModalMode('EDIT_GROUP');
    this.showModal();
  }

  manageUsers(group: GroupDTO): void {
    this.setGroupToEdit(group);
    this.setModalMode('MANAGE_USERS');
    this.showModal();
  }

  setGroupToEdit(group: GroupDTO):void {
    this.groupToEdit = group;
  }

  unsetGroupToEdit(): void {
    this.groupToEdit = null;
  }

  showModal(): void {
    this.modalIsVisible = true;
  }

  setModalMode(mode: ModalMode): void {
    this.modalMode = mode;
  }

  hideModal(): void {
    this.modalIsVisible = false;
    this.setModalMode(null);
    this.unsetGroupToEdit;
  }

  onGroupCreated(group: CreateGroupPayload): void {
    //Todo: adicionar o grupo na lista sem um novo request. será necessário receber o uuid do grupo novo ao cadastrar
    this.getGroups();
    this.hideModal();
  }

  async getGroups(): Promise<void> {
    try {
      const { groups } = await this.$store.dispatch('getAllGroups');
      this.allGroups = groups;
    } catch (e) {
      this.allGroups = [];
    }
  }

  async deleteGroup(group: GroupDTO): Promise<void> {
    try {
      await this.$store.dispatch('deleteGroup', group);
      this.$store.commit('showAlert', { message: 'Regra removida com sucesso!', type: 'success'});
      const deletedItemIndex: number = this.allGroups.findIndex((g) => g.uuid === group.uuid);
      this.allGroups.splice(deletedItemIndex, 1);
    } catch (e) { return; }
  }

  async updateGroupStatus(group: GroupDTO): Promise<void> {
    try {
      const updatedGroup = {...group, active: !group.active};
      await this.$store.dispatch('updateGroupStatus', updatedGroup);
      group.active = updatedGroup.active;
      this.$store.commit('showAlert', { message: 'Status alterado com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  @Watch('allGroups')
  onGroupsChanged(groups: GroupDTO[]): void {
    this.filteredGroups = groups;
  }

  mounted(): void {
    this.getGroups();
    this.$root.$on('bv::modal::hide', () => {
      this.unsetGroupToEdit();
    })
  }
}
