
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import ModalFormHeader from '@/components/ModalFormHeader.vue';
import PillsList from '@/components/PillsList.vue';
import SearchProgramsForm from '@/components/SearchProgramsForm.vue';
import CustomGroupedCheckboxList from '@/components/CustomGroupedCheckboxList.vue';
import { GroupDTO } from '@/data/dto/group.dto';
import { Weekday } from '@/data/dto/weekday.dto';
import { ResearchFilterDTO } from '@/data/dto/research-filter.dto';

type Permission = { key: string; value: string };
type CreateGroupPayload = {
  name: string;
  description: string;
  permissions: Permission[];
  uuid: string | undefined;
  hidden?: boolean;
};
type DayOption = { label: string; value: Weekday; };

@Component({
  components: {
    ModalFormHeader,
    PillsList,
    CustomGroupedCheckboxList,
    SearchProgramsForm,
  }
})
export default class GroupForm extends Mixins(FormValidationMixin) {
  @Prop() private groupToEdit?: GroupDTO;

  get features(): string[] {
    return this.allFeatures.map(f => f.value);
  }

  permissions: Record<string, Permission[]> = {
    marketId: [],
    programId: [],
    weekDay: [],
    feature: [],
  };

  group: CreateGroupPayload = {
    name: '',
    description: '',
    uuid: undefined,
    hidden: false,
    permissions: []
  };

  allMarkets: ResearchFilterDTO[] = [];
  allFeatures: ResearchFilterDTO[] = [];
  programsList: ResearchFilterDTO[] = [];
  programsLabelsList: ResearchFilterDTO[] = [];
  allDays: DayOption[] = [
    { label: 'D', value: 'DOMINGO' },
    { label: 'S', value: 'SEGUNDA' },
    { label: 'T', value: 'TERCA' },
    { label: 'Q', value: 'QUARTA' },
    { label: 'Q', value: 'QUINTA' },
    { label: 'S', value: 'SEXTA' },
    { label: 'S', value: 'SABADO' },
  ];

  formValidations: IFormValidations = {
    name: () => !!this.group.name,
    description: () => !!this.group.description,
    markets: () => !!this.permissions.marketId.length,
  };

  get pageTitle(): string {
    return this.group.uuid ? 'Editar regra' : 'Incluir regra';
  }

  cancel(): void {
    this.$emit('canceled');
  }

  cloneGroup(): void {
    this.$store.dispatch('showFakeLoading');
    this.group.uuid = undefined;
    this.group.name = '';
  }

  async getAuthorizationConfigs(): Promise<void> {
    try {
      const { marketsId, features } = await this.$store.dispatch('getAuthorizationConfigs');
      this.allMarkets = marketsId;
      this.allFeatures = features;
    } catch (e) {
      this.allMarkets = [];
      this.allFeatures = [];
    }
  }

  async createGroup(): Promise<void> {
    try {

      await this.$store.dispatch('createGroup', this.group);

      this.$store.commit('showAlert', { message: 'Regra criada com sucesso!', type: 'success'});
      this.$emit('groupCreated', this.group);
    } catch (e) { return; }
  }

  async updateGroup(): Promise<void> {
    try {
      await this.$store.dispatch('updateGroup', this.group);
      this.$store.commit('showAlert', { message: 'Regra atualizada com sucesso!', type: 'success'});
      this.$emit('groupCreated', this.group);
    } catch (e) { return; }
  }

  @Watch('programsList')
  onProgramsListChanged(programs: ResearchFilterDTO[]): void {
    this.programsLabelsList = this.programsLabelsList.concat(programs);
  }

  generateProgramLabel(programId:string): string {
    try {
      return this.programsLabelsList.filter(program => program.value===programId)[0].label;
    } catch {
      return 'programa';
    }
  }

  onSubmit(): void {

    if (!this.validateForm()) return;

    this.parseFormPermissionToGroup();

    if (this.group.uuid) {
      this.updateGroup();
    } else {
      this.createGroup();
    }
  }

  async getGroup(): Promise<void> {
    try {
      //todo: remover validação após padronização de uuid e UUID
      if (!(this.groupToEdit && this.groupToEdit.uuid)) return;
      const currentGroup = await this.$store.dispatch('getOneGroup', { uuid: this.groupToEdit.uuid });
      this.group.uuid = this.groupToEdit.uuid;
      this.group.name = currentGroup.name;
      this.group.description = currentGroup.description;
      this.group.hidden = !!currentGroup.hidden;
      this.parseGroupPermissionToForm(currentGroup.policies);
    } catch (e) { return; }
  }

  parseFormPermissionToGroup(): void {
    try {
      this.group.permissions = [
        ...this.permissions.marketId,
        ...this.permissions.programId,
        ...this.permissions.weekDay,
        ...this.permissions.feature,
      ];
    } catch(e) {
      this.group.permissions = [];
    }
  }

  parseGroupPermissionToForm(policies:any): void {
    const keys = Object.keys(policies);

    keys.forEach(key => {
      console.log('key', key)
      // se for uma feature, coloca no array correspondente
      if (this.features.includes(key)) {
        console.log('é feature', key)
        this.permissions.feature.push({key:key, value:'true'});
      //senão, trata os casos de array de outras permissões
      } else if (Array.isArray(policies[key])){
        policies[key].forEach((policy:string) => {
          if(this.permissions[key]) this.permissions[key].push({key:key, value:policy});
        });
      // e os valores únicos de outras permissões
      } else {
        if(this.permissions[key]) this.permissions[key].push({key:key, value:policies[key]});
      }
    });
  }

  async getCurrentProgramsData(): Promise<void> {
    try {
      const programIdsList = this.permissions.programId.map(p => p.value);
      if (!programIdsList.length) return;
      const { programs } = await this.$store.dispatch('searchPrograms', {
        programIds: programIdsList,
      });
      this.programsLabelsList = programs;
    } catch { return; }
  }

  async mounted(): Promise<void> {
    await this.getAuthorizationConfigs();
    if (this.groupToEdit) {
      await this.getGroup();
      //pega os dados de programa para montar as labels da lista de programas selecionados
      await this.getCurrentProgramsData();
    }
  }
}
