
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
type CollectionItem = { [key:string]: any } | string;

@Component
export default class SearchBox extends Vue {  
  @Prop(Array) private collection!: CollectionItem[];
  @Prop(Array) private keys!: string[];  

  searchTerms = '';  

  searchCollection(): void {
    let result: CollectionItem[] = [];

    if (this.searchTerms) {
      result = this.collection.filter((item): boolean => {
        //se não houver chaves definidas ou se for um array de strings, verifica diretamente o termo digitado
        if(!this.keys.length || typeof(item)=='string') return item.toUpperCase().includes(this.searchTerms.toUpperCase());
        //senão, verificar o termo para cada atributo incluído na busca
        return this.keys.reduce<boolean>((acc, k): boolean => {
          return acc || (item[k] && item[k].toUpperCase().includes(this.searchTerms.toUpperCase()))
        }, false);
      });
      this.$emit('searchFinished', result);   
    }      
  }

  @Watch('searchTerms')
  onSearchTermsChanged(text: string): void {
    if (!text) this.$emit('searchReseted');
  }
}
